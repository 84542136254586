<template>
  <div class="login-outer">
    <old-login></old-login>
  </div>
</template>

<script>
// import { md5 } from '@/utils/md5'
import md5 from "js-md5";
import Cookies from "js-cookie";
import oldLogin from '../../components/login/oldLogin'
export default {
  name: "Login",
  components:{
    oldLogin,
  },
};
</script>
<style>
  .login .login-form .el-input__inner {
        background-color: #FFF;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #E6E6E6;
  }
  .login .login-form .el-button {
    height: 46px;
    border-radius: 23px;
  }
</style>
<style rel="stylesheet/less" lang="less" type="text/less" scoped>
.login-outer {
  height: 100%;

}


</style>
